import React, { Component } from "react";

export class Services extends Component {
  render() {
    return (
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div  key={`${d.name}-${i}`} className="col-md-4">
                    {" "}
                    <i className={d.icon}></i>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
          <p>
            For each project we assign a Project Manager to manage the daily requests between you (the client) and the developer or development team assigned to the project. Our manager will send you daily progress updates giving you assurance your project is being carefully managed and tasks are being handled iteratively.
          </p>
          <p className="quote">
          *All our managers, developers, designers and consultants reside/work in Europe.
          </p>
        </div>
      </div>
    );
  }
}

export default Services;
